
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import Vue from "vue";
import { DataEmitterService } from '@/services/DataEmiterService'
import * as moment from "moment";

export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      organizationsSnippet: snippets ?? {},
      organizationsList: [],
      paginatedOrganizationsList: [],
      paginatedSearchedOrganizationsList: [],
      organizationsPaginatedList: [],
      rolesList: [],
      organizationsListUntouched: [],
      searchedData: [],
      searchKey: '',
      searchField: '',
      newEventName: '',
      addOrEditSpace: '',
      dialog: false,
      spaceDialog: false,
      eventDialog: false,
      editEventDialog: false,
      addEventsDialog: false,
      dialogDelete: false,
      deleteEventDialog: false,
      dialogUpgrade: false,
      submitted: false,
      eventsSubmitted: false,
      spaceSubmitted: false,
      emptyReportData: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        fullAddress: '',
        email: '',
        phoneNumber: '',
        vatNumber: null,
        sdiCode: null,
        creditsForEvents: null,
        creditsForBookings: null,
      },
      defaultItem: {
        id: '',
        name: '',
        fullAddress: '',
        email: '',
        phoneNumber: '',
        vatNumber: null,
        sdiCode: null,
        creditsForEvents: null,
        creditsForBookings: null
      },
      multiSelectRoles: [],
      sortBy: 'id',
      editedPrefix: '',
      sortDesc: true,
      errorKey: '',
      addEventsDialogErrorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldOrganization: {},
      editedEventItem: {},
      toDeleteEvent: {},
      addressesDataList: [],
      showSpaces: [],
      organizationData: [],
      defaultFloor: {
        id: 0,
        name: 'default',
        description: ''
      },
      eventsList: [],
      eventsNameList: [],
      oldEventsList: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
    }
  },

  computed: {

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    formTitle() {
      return this.editedIndex === -1 ? this.organizationsSnippet.add_organization : this.organizationsSnippet.edit_organization
    },

    headers() {
      return [
        { text: this.organizationsSnippet.id, value: 'id', width: '0px', sortable: true },
        { text: this.organizationsSnippet.name, value: 'name', width: '0px', sortable: true },
        {
          text: this.organizationsSnippet.fullAddress,
          value: 'fullAddress',
          width: '0px',
          sortable: true
        },
        { text: this.organizationsSnippet.email, value: 'email', width: '0px', sortable: true },
        {
          text: this.organizationsSnippet.phoneNumber,
          value: 'phoneNumber',
          width: '0px',
          sortable: true
        },
        {
          text: this.organizationsSnippet.vatNumber,
          value: 'vatNumber',
          width: '0px',
          sortable: true
        },
        { text: this.organizationsSnippet.sdiCode, value: 'sdiCode', width: '0px', sortable: true },
        {
          text: this.organizationsSnippet.creditsForEvents,
          value: 'creditsForEvents',
          width: '0px',
          sortable: true
        },
        {
          text: this.organizationsSnippet.creditsForBookings,
          value: 'creditsForBookings',
          width: '0px',
          sortable: true
        },
        { text: this.organizationsSnippet.actions, value: 'actions', width: '5%', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    addEventsDialog( val ) {
      val || this.closeAddEventsDialog()
    },
    eventDialog( val ) {
      val || this.closeEventDialog()
    },

    editEventDialog( val ) {
      val || this.closeEditEventDialog()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    deleteEventDialog( val ) {
      val || this.closeDeleteEvent()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.searchField = ''
        this.searchedData = [];
        this.search$.next( '' );
      }
    },

  },

  async mounted() {
    await this.getOrganizationsApi();

    this.loading = false;
    this.searchAction();
  },

  methods: {
    sortData() {
      const compare = ( a, b ) => {
        let result = 0;

        switch ( this.sortBy ) {
          case 'id':
          case 'name':
          case 'fullAddress':
          case 'email':
          case 'phoneNumber':
          case 'vatNumber':
          case 'sdiCode':
          case 'creditsForEvents':
          case 'creditsForBookings':
            if ( !b[this.sortBy] ) {
              result = 1;
            }

            result = a[this.sortBy] > b[this.sortBy] ? 1 : -1;
            break;
        }

        return this.sortDesc ? -result : result;
      };

      this.searchedDataOriginal?.sort( compare );
      this.organizationData?.sort( compare );

      [ ...Object.values( this.searchedOrganizationsPaginatedList ?? [] ), ...Object.values( this.organizationsPaginatedList ?? [] ) ].forEach( set => {
        if ( set instanceof Set ) {
          set.clear();
        } else if ( Array.isArray( set ) ) {
          set.length = 0;
        }
      } );

      this.searchedDataOriginal?.forEach( ( item, i ) => {
        const index = Math.floor( i / 10 );
        if ( !this.searchedOrganizationsPaginatedList[index + 1] ) {
          this.searchedOrganizationsPaginatedList[index + 1] = [];
        }
        this.searchedOrganizationsPaginatedList[index + 1].push( item )
      } );

      this.organizationData?.forEach( ( item, i ) => {
        const index = Math.floor( i / 10 );
        if ( !this.organizationsPaginatedList[index + 1] ) {
          this.organizationsPaginatedList[index + 1] = new Set();
        }
        this.organizationsPaginatedList[index + 1].add( item )
      } );
    },

    async sortTable() {
      await Promise.resolve();
      this.sortData();
      await this.changePage( this.currentPage );
    },

    searchAction() {
      this.search$ = new Subject();
      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getOrganizationsPaginated();
          return
        }

        let data = await this.searchText( term );
        if ( data ) {
          await this.getSearchedOrganizationsPaginated( data.data );
          this.sortTable();
        }
      } )
    },

    async getSearchedOrganizationsPaginated( data ) {
      let organizationsCount = data.length;
      this.pagesCount = Math.ceil( organizationsCount / 10 )

      this.searchedOrganizationsList = [];
      this.paginatedSearchedOrganizationsList.length = 0;
      this.searchedOrganizationsPaginatedList = {};

      await this.setSearchedOrganizationsToOrganizationsList( data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedOrganizationsList )
    },

    async searchText( term ) {
     return ApiRequestService.getRequest( `api/admin-organizations?nameFilter=${ term }` );
    },

    async changePage( page ) {
      this.currentPage = page;

      if ( this.searchField ) {
        if ( this.searchedDataOriginal?.length ) {
          await this.setSearchedOrganizationsToOrganizationsList( this.searchedDataOriginal );
        }
        return;
      }

      if ( this.organizationData?.length ) {
        await this.setOrganizationsToOrganizationsList( this.organizationData );
      }
    },

    async setSearchedOrganizationsToOrganizationsList( data ) {
      data.forEach( ( organization, index ) => {
        let existsIndex = this.paginatedSearchedOrganizationsList.findIndex( item => item.id === organization?.id );
        let organizationData = {
          id: organization?.id,
          name: organization?.name,
          fullAddress: organization?.fullAddress,
          email: organization?.email,
          phoneNumber: organization?.phoneNumber,
          vatNumber: organization?.vatNumber,
          sdiCode: organization?.sdiCode,
          creditsForEvents: organization?.creditsForEvents,
          createDate: this.getToLocaleDate( organization?.createDate ),
        };
        if ( existsIndex !== -1 ) {
          let searchedOrganizationsPaginatedListClone = this.getObjClone( this.searchedOrganizationsPaginatedList[this.currentPage] )

          searchedOrganizationsPaginatedListClone.forEach( ( item, index ) => {
            if ( item?.id === organizationData?.id ) {
              this.searchedOrganizationsPaginatedList[this.currentPage][index] = organizationData
            }
          } );
          return;
        }

        if ( !this.searchedOrganizationsPaginatedList[this.currentPage] ) {
          this.searchedOrganizationsPaginatedList[this.currentPage] = [];
        }
        if ( this.searchedOrganizationsPaginatedList[this.currentPage].length < 10 ) {
          this.searchedOrganizationsPaginatedList[this.currentPage].push( organizationData );
        }
      } )

      if ( this.searchedOrganizationsPaginatedList[this.currentPage] ) {
        this.paginatedSearchedOrganizationsList = this.getObjClone( this.searchedOrganizationsPaginatedList[this.currentPage] )
      }
    },

    async getOrganizationsPaginated() {
      let organizationsCount = this.organizationData.length;
      this.pagesCount = Math.ceil( this.organizationData.length / 10 );

      if ( this.pagesCount === 1 ) {
        this.currentPage = 1;
      }

      if ( organizationsCount ) {
        this.setOrganizationsByPages( this.organizationData );
        await this.setOrganizationsToOrganizationsList( this.organizationData );
      }
    },

    async getOrganizationsApi() {
      DataEmitterService.$emit( 'showLoader', true );

      this.organizationData = await ApiRequestService.getRequest( `api/admin-organizations` );
      this.organizationData = this.organizationData.data;
      if ( !this.organizationData?.length ) {
        setTimeout( () => {
          DataEmitterService.$emit( 'showLoader', false );
        }, 200 )
        return;
      }
      await this.getOrganizationsPaginated();
      let organizationsCount = this.organizationData?.length ?? 0;
      if ( organizationsCount ) {
        await this.setOrganizationsToOrganizationsList( this.organizationData );
      }
      await this.sortTable()

      setTimeout( () => {
        DataEmitterService.$emit( 'showLoader', false );
      }, 200 )
    },

    async selectPerPageCount( event ) {
      if ( event === this.organizationData?.length ) {
        return
      }

      // this.organizationData = await ApiRequestService.getRequest( `api/organizations?offset=${ this.organizationData?.data?.length }&limit=${ event + 1 }` );
      // let page = Math.ceil( event / 10 );
      // this.organizationData = await ApiRequestService.getRequest( `api/organizations?page=${ page }` );

      if ( this.organizationData?.length ) {
        await this.setOrganizationsToOrganizationsList( this.organizationData );
      }
    },

    async setOrganizationsToOrganizationsList( organizationData ) {
      organizationData?.forEach( ( organization, index ) => {

        let existsIndex = this.organizationsList.findIndex( item => item.id === organization?.id );
        let organizationData = {
          id: organization?.id,
          name: organization?.name,
          fullAddress: organization?.fullAddress,
          email: organization?.email,
          phoneNumber: organization?.phoneNumber,
          vatNumber: organization?.vatNumber,
          sdiCode: organization?.sdiCode,
          creditsForEvents: organization?.creditsForEvents,
          creditsForBookings: organization?.creditsForBookings,
          // createDate: organization?.createDate ? this.getToLocaleDate( organization?.createDate ) : null,
        };

        if ( existsIndex !== -1 ) {
          Vue.set( this.organizationsList, existsIndex, organizationData )
          let organizationsPaginatedListClone = [ ...this.organizationsPaginatedList[this.currentPage].values() ]
          organizationsPaginatedListClone.forEach( item => {
            if ( item?.id === organizationData?.id ) {
              this.organizationsPaginatedList[this.currentPage].delete( item )
              this.organizationsPaginatedList[this.currentPage].add( organizationData )
            }
          } );
          return;
        }

        Vue.set( this.organizationsList, this.organizationsList.length, organizationData )

        if ( !this.organizationsPaginatedList[this.currentPage] ) {
          this.organizationsPaginatedList[this.currentPage] = new Set();
        }
        if ( this.organizationsPaginatedList[this.currentPage].size < 10 ) {
          this.organizationsPaginatedList[this.currentPage].add( organizationData );
        }
      } )
      this.organizationsListUntouched = this.organizationsList
      if ( this.organizationsPaginatedList[this.currentPage] ) {
        this.paginatedOrganizationsList = this.getObjClone( [ ...this.organizationsPaginatedList[this.currentPage] ] )
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    addOrganizationToOrganizationList( data ) {
      let newOrganizationData = {
        id: data.id,
        name: data.name,
        fullAddress: data.fullAddress,
        email: data.email,
        phoneNumber: data.phoneNumber,
        vatNumber: data.vatNumber,
        sdiCode: data.sdiCode,
        creditsForEvents: data.creditsForEvents,
        creditsForBookings: data.creditsForBookings,
        // createDate: data.createDate ? this.getToLocaleDate( data.createDate ) : null
      };
      let existsIndex = this.organizationData?.findIndex( organization => organization.id === newOrganizationData.id );

      if ( existsIndex !== -1 && existsIndex !== undefined ) {
        this.organizationData[existsIndex] = newOrganizationData;
      } else {
        this.organizationData.push( newOrganizationData );
        this.organizationData.totalElements++;
      }
      this.setOrganizationsByPages( this.organizationData );
      this.setOrganizationsToOrganizationsList( this.organizationData )
    },

    async getChangedOrganizationData( oldOrganization ) {
      const dataFormUpdate = {};

      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldOrganization[key] === this.editedItem[key] ) {
          continue
        }

        dataFormUpdate[key] = this.editedItem[key]
      }

      if ( this.editedItem?.photoIds?.length ) {
        dataFormUpdate['photoIds'] = await this.uploadOrganizationImages( this.editedItem );
      }

      return dataFormUpdate
    },

    dataIsValid() {
      const nameIsValid = this.organizationIsValid( this.editedItem?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    organizationIsValid( v ) {
      return ( v && v.length > 0 ) || this.organizationsSnippet.required
    },

    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedOrganizationsList : this.paginatedOrganizationsList;
      let list = this.searchField ? this.searchedOrganizationsList : this.organizationsList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      await this.getOrganizationEventsApi( this.editedItem.id );

      this.dialog = true
      this.disableKeyField = true
      this.oldOrganization = this.getObjClone( this.editedItem )
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedOrganizationsList : this.paginatedOrganizationsList;
      this.editedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },


    async deleteItemConfirm() {
      let item = await ApiRequestService.deleteRequest( `api/admin-organizations/${ this.editedItem.id }` );
      if ( item ) {
        let paginatedList = this.searchField ? this.paginatedSearchedOrganizationsList : this.paginatedOrganizationsList;
        let list = this.searchField ? this.searchedOrganizationsList : this.organizationsList;
        paginatedList.splice( this.editedIndex, 1 )
        list.splice( this.listEditedIndex, 1 )
        this.organizationData = this.organizationData.filter( item => item.id !== this.editedItem.id );
        this.organizationsList = this.organizationsList.filter( item => item.id !== this.editedItem.id );
        await this.getOrganizationsPaginated();
        await this.setOrganizationsByPages( this.organizationData );
      }

      this.closeDelete()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    close() {
      this.dialog = false;
      this.submitted = false;
      this.errorKey = '';
      // this.$refs.fileupload.reset();

      /**
       * if action is edit, set to form old data
       */
      let data;
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldOrganization );
      } else {
        data = this.getObjClone( this.defaultItem );
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue );
          }
        } else {
          this.$set( this.editedItem, key, value );
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },


    closeDelete() {
      this.dialogDelete = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    setNewOrganizationToOrganizationsList( organizationData ) {
      if ( organizationData ) {

        this.addOrganizationToOrganizationList( organizationData );

        this.close();
      } else if ( organizationData.errorMessage ) {
        console.log( 'organizationData.errorMessage', organizationData.errorMessage )
      }
    },


    openDetails( organization ) {
      if ( organization && organization.id ) {
        this.$router.push( `organization-details/${ organization.id }` )
      }
    },

    async save() {
      this.submitted = true;

      await this.saveOrganization();
    },

    async saveOrganization() {
      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        return;
      }
      this.editedItem.creditsForBookings = +this.editedItem.creditsForBookings;
      this.editedItem.creditsForEvents = +this.editedItem.creditsForEvents;
      this.editedItem.sdiCode = +this.editedItem.sdiCode;
      this.editedItem.vatNumber = +this.editedItem.vatNumber;
      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( value ) {
          dataToCreate[key] = value;
        }
      }

      const organizationData = await ApiRequestService.postRequest( 'api/admin-organizations', dataToCreate );
      if ( organizationData?.errorMessage ) {
        console.log( 'organizationData?.errorMessage', organizationData?.errorMessage )
        this.errorKey = organizationData?.errorMessage?.split( ',' );
        return;
      }
      if ( !organizationData ) {
        return;
      }

      this.setNewOrganizationToOrganizationsList( organizationData );
    },
    setOrganizationsByPages( data ) {
      let currentItems = this.getObjClone( data );

      for ( let pageIndex = 1; pageIndex <= this.pagesCount; pageIndex++ ) {
        for ( let itemIndex = 0; itemIndex < 10; itemIndex++ ) {
          if ( !currentItems[itemIndex] ) {
            break;
          }

          let existsIndex = this.organizationsList.findIndex( item => item.id === currentItems[itemIndex]?.id );
          let organizationData = {
            id: currentItems[itemIndex]?.id,
            name: currentItems[itemIndex]?.name,
            fullAddress: currentItems[itemIndex]?.fullAddress,
            email: currentItems[itemIndex]?.email,
            phoneNumber: currentItems[itemIndex]?.phoneNumber,
            vatNumber: currentItems[itemIndex]?.vatNumber,
            sdiCode: currentItems[itemIndex]?.sdiCode,
            creditsForEvents: currentItems[itemIndex]?.creditsForEvents,
            // createDate: organization?.createDate ? this.getToLocaleDate( organization?.createDate ) : null,
          };

          if ( existsIndex !== -1 ) {
            Vue.set( this.organizationsList, existsIndex, organizationData )
            let organizationsPaginatedListClone = []
            if ( this.organizationsPaginatedList[pageIndex] ) {
              organizationsPaginatedListClone = [ ...this.organizationsPaginatedList[pageIndex].values() ]
            }
            organizationsPaginatedListClone.forEach( item => {
              if ( item?.id === organizationData?.id ) {
                this.organizationsPaginatedList[pageIndex].delete( item )
                this.organizationsPaginatedList[pageIndex].add( organizationData )
              }
            } );
            continue;
          }

          Vue.set( this.organizationsList, this.organizationsList.length, organizationData )

          if ( !this.organizationsPaginatedList[pageIndex] ) {
            this.organizationsPaginatedList[pageIndex] = new Set();
          }
          if ( this.organizationsPaginatedList[pageIndex].size < 10 ) {
            this.organizationsPaginatedList[pageIndex].add( organizationData );
          }
        }
        currentItems = currentItems.splice( pageIndex * 10 );
      }

      this.organizationsListUntouched = this.organizationsList

      if ( this.organizationsPaginatedList[this.currentPage] ) {
        this.paginatedOrganizationsList = this.getObjClone( [ ...this.organizationsPaginatedList[this.currentPage] ] )
      }
    }
  }
}

